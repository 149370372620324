import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';

import { TransactionService } from '../../services/transaction.service';
import { TransactionDetailsComponent } from '../dialogs/transaction-details/transaction-details.component';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-searchcellno',
  templateUrl: './searchcellno.component.html',
  styleUrls: ['./searchcellno.component.css']
})
export class SearchCellnoComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dataTable', { static: false }) dataTable: ElementRef;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  token = localStorage.getItem('Authorization');

  private ngUnsubscribe: Subject<any> = new Subject();

  reportForm: FormGroup;

  public link: any;

  private displayedColumns: string[] = ['Description', 'Cell_No', 'UD_Short', 'Create_Stamp', 'SMSC_Stamp', 'Contact', 'Action'];

  private transactions: any;
  private dataSource = new MatTableDataSource();

  private loadedTransactions: Array<any>;
  private pipe = new DatePipe('en-ZA'); // Use your own locale
  private now = Date.now();
  private tableTransLimit: number = 5000;
  public loading: boolean;

  constructor(
    private transactionService: TransactionService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private loaderService: LoaderService
  ) {
    this.reportForm = new FormGroup(
      {
        startDteCtrl: new FormControl(new Date(), Validators.required),
        endDteCtrl: new FormControl(new Date(), Validators.required),
        cellNoCtrl: new FormControl('', Validators.required),
      });
    this.loaderService.isLoading.subscribe((v) => { this.loading = v; });
  }

  get p() {
    return this.reportForm.controls;
  }

  get startDteCtrl() {
    return this.reportForm.get('startDteCtrl');
  }

  get endDteCtrl() {
    return this.reportForm.get('endDteCtrl');
  }

  get cellNoCtrl() {
    return this.reportForm.get('cellNoCtrl');
  }

  ngOnInit() {
    this.transactions = [];
    this.loadedTransactions = [];
    this.loading = false;
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  logout() {
    window.location.replace('/login');
    localStorage.removeItem('Authorization');
    this.token = null;
  }

  onRunReport() {
    if (this.reportForm.valid) {
      let startDte = this.pipe.transform(this.startDteCtrl.value, 'yyyy-MM-dd') + ' 00:00:00';
      let endDte = this.pipe.transform(this.endDteCtrl.value, 'yyyy-MM-dd') + ' 23:59:59';
      let searchCellNo = this.cellNoCtrl.value;
      let payload = {
        cellNo: searchCellNo,
        fromDte: startDte,
        toDte: endDte,
        limit: this.tableTransLimit
      }
      let totalRows = 0;
      this.transactions = [];
      this.loadedTransactions = [];
      this.dataSource.data = this.transactions;
      this.transactionService.getCellNoTransactions(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          data => {
            this.transactions = data;
            if (this.transactions.rows.length === 0) {
              this.openSnackBar('No data found for the date selection', 'OK');
            } else {
              if (this.transactions.rows.length > this.tableTransLimit) {
                totalRows = this.tableTransLimit;
              } else {
                totalRows = this.transactions.rows.length;
              }
              for (let index = 0; index < totalRows; index++) {
                const element = this.transactions.rows[index];
                element.UD_Short = element.UD_Short + '...';
                this.loadedTransactions[index] = element;
              }
              this.transactions = this.loadedTransactions;
              this.loadedTransactions = [];
              this.dataSource.data = this.transactions;
            }
          })
    } else {
      this.openSnackBar('Please select a valid start, end date and cell phone number', 'OK');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDownloadReport() {
    if (this.reportForm.valid) {
      let reportTransactions: any;
      let searchCellNo = this.cellNoCtrl.value;
      let startDte = this.pipe.transform(this.startDteCtrl.value, 'yyyy-MM-dd') + ' 00:00:00';
      let endDte = this.pipe.transform(this.endDteCtrl.value, 'yyyy-MM-dd') + ' 23:59:59';
      let payload = {
        cellNo: searchCellNo,
        fromDte: startDte,
        toDte: endDte,
        limit: 1048500
      }
      this.transactionService.getCellNoTransactions(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          data => {
            reportTransactions = data;
            if (reportTransactions.rows.length === 0) {
              this.openSnackBar('No data found for the date selection', 'OK');
            } else {
              const rows = reportTransactions.rows.map(row => ({
                Seq_No: row.Seq_No,
                Status: row.Status_Desc.Description,
                Error: row.Error,
                Cell_No: row.Cell_No,
                UD: row.UD,
                Create_Stamp: row.Create_Stamp,
                SMSC_Stamp: row.SMSC_Stamp,
                Deliver_Stamp: row.Deliver_Stamp,
                Concat_No: (row.Concat_No === 0) ? 1 : row.Concat_No,
                Concat_Max: (row.Concat_Max === 0) ? 1 : row.Concat_Max,
                Concat_Ref: row.Concat_Ref,
                Contact: row.Contact,
                AppName: row.AppName
              }));

              const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);

              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

              /* save to file */
              startDte = this.pipe.transform(this.startDteCtrl.value, 'yyyy-MM-dd');
              endDte = this.pipe.transform(this.endDteCtrl.value, 'yyyy-MM-dd');
              XLSX.writeFile(wb, `Report_${startDte}_${endDte}.xlsx`);
            }
          })
    } else {
      this.openSnackBar('Please select a valid start and end date', 'OK');
    }
  }

  openDialog(element): void {
    element = element;
    let dialogRef = this.dialog.open(TransactionDetailsComponent, {
      width: '1000px',
      disableClose: true,
      data: { element: element, originalform: this }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../environments/environment";
import { Observable, of } from 'rxjs';


const transactionUrl = environment.dashboardUrl;

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    token = localStorage.getItem('Authorization');

    httpOptions = {
        headers: {}
    };

    constructor(private httpClient: HttpClient) { }

    getQueueCount() {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.get(transactionUrl + '/getqueued', this.httpOptions);
        }
        return;
    }

    getOutboundTodayCount() {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.get(transactionUrl + '/getoutboundtoday', this.httpOptions);
        }
        return;
    }

    getOutboundMonthCount() {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.get(transactionUrl + '/getoutboundmonth', this.httpOptions);
        }
        return;
    }

    getInboundMonthCount() {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.get(transactionUrl + '/getinboundmonth', this.httpOptions);
        }
        return;
    }
}
import { NgModule } from '@angular/core';
import {
    MatNativeDateModule, MatIconModule, MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatFormFieldModule,
    MatInputModule, MatRadioModule, MatListModule, MatSnackBarModule, MatSidenavModule, MatDividerModule, MatTableModule, MatDialogModule,
    MatGridListModule, MatSelectModule, MatOptionModule, MatExpansionModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';



@NgModule({
    imports: [MatNativeDateModule, MatDatepickerModule, MatIconModule, MatButtonModule, MatCheckboxModule, MatToolbarModule, FormsModule, MatCardModule, MatFormFieldModule, MatInputModule,
        MatListModule, MatRadioModule, MatSnackBarModule, MatSidenavModule, MatDividerModule, MatTableModule, MatDialogModule, MatGridListModule, MatSelectModule, MatOptionModule,
        MatExpansionModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatSortModule, MatTooltipModule],

    exports: [MatNativeDateModule, FormsModule,
        MatDatepickerModule, MatIconModule, MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatFormFieldModule, MatInputModule, MatListModule, MatRadioModule,
        MatSnackBarModule, MatSidenavModule, MatDividerModule, MatTableModule, MatDialogModule, MatGridListModule, MatSelectModule, MatOptionModule,
        MatExpansionModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatSortModule, MatTooltipModule],

})

export class MyMaterialModule { }
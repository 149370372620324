import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../environments/environment";
import { Observable, of } from 'rxjs';

import { Transaction } from 'src/models/transaction-model';

const transactionUrl = environment.transactionUrl;

@Injectable({
    providedIn: 'root'
})
export class TransactionService {
    token = localStorage.getItem('Authorization');

    httpOptions = {
        headers: {}
    };

    constructor(private httpClient: HttpClient) { }

    getAllTransactions(payload): Observable<Transaction[]> {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.post<Transaction[]>(transactionUrl + '/getitemizedalltrans', payload, this.httpOptions);
        }
        return;
    }

    getOutboundTransactions(payload): Observable<Transaction[]> {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.post<Transaction[]>(transactionUrl + '/getitemizedoutboundtrans', payload, this.httpOptions);
        }
        return;
    }

    getInboundTransactions(payload): Observable<Transaction[]> {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.post<Transaction[]>(transactionUrl + '/getitemizedinboundtrans', payload, this.httpOptions);
        }
        return;
    }

    getCellNoTransactions(payload): Observable<Transaction[]> {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.post<Transaction[]>(transactionUrl + '/getcellnotrans', payload, this.httpOptions);
        }
        return;
    }

    getContactCount(payload) {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.post(transactionUrl + '/getcontactcount', payload, this.httpOptions);
        }
        return;
    }

}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/services/login.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  submitted: boolean = false;
  loading: boolean = false;
  payload: any = {};
  resp: any = {};

  loginEmailAddr = new FormControl('', [
    Validators.required
  ]);
  loginPassword = new FormControl('', [
    Validators.required
  ]);

  constructor(
    private router: Router,
    private loginService: LoginService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.submitted = false;
    this.loading = false;
    this.payload = {};
    this.resp = {};
  }

  onUserLogin() {
    this.submitted = true;
    this.processLogin();
  }

  processLogin() {
    this.loading = true;
    this.payload = {
      Service_No: this.loginEmailAddr.value,
      Password: this.loginPassword.value
    };
    this.loginService.login(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          if (this.resp.token === null) {
            this.openSnackBar("Invalid user account details", "OK");
            return
          } else if (this.resp.token !== null) {
            window.location.replace('/dashboard');
            //this.router.navigateByUrl("/dashboard");
          }
        },
        error => {
          this.openSnackBar("Invalid user account details", "OK");
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition: 'top'
    });
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

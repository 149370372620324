import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';

import { TransactionService } from '../../services/transaction.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-contact-report',
  templateUrl: './contact-report.component.html',
  styleUrls: ['./contact-report.component.css']
})
export class ContactReportComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dataTable', { static: false }) dataTable: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  token = localStorage.getItem('Authorization');

  private ngUnsubscribe: Subject<any> = new Subject();

  reportForm: FormGroup;

  public link: any;

  private displayedColumns: string[] = ['Contact', 'TagCount'];

  private transactions: any;
  private dataSource = new MatTableDataSource();

  private loadedTransactions: Array<any>;
  private pipe = new DatePipe('en-ZA'); // Use your own locale
  private now = Date.now();
  private tableTransLimit: number = 100;
  public loading: boolean;

  constructor(
    private transactionService: TransactionService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private loaderService: LoaderService
  ) {
    this.reportForm = new FormGroup(
      {
        startDteCtrl: new FormControl(new Date(), Validators.required),
        endDteCtrl: new FormControl(new Date(), Validators.required),
      });
    this.loaderService.isLoading.subscribe((v) => { this.loading = v; });
  }

  get p() {
    return this.reportForm.controls;
  }

  get startDteCtrl() {
    return this.reportForm.get('startDteCtrl');
  }

  get endDteCtrl() {
    return this.reportForm.get('endDteCtrl');
  }

  ngOnInit() {
    this.transactions = [];
    this.loadedTransactions = [];
    this.loading = false;
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  logout() {
    window.location.replace('/login');
    localStorage.removeItem('Authorization');
    this.token = null;
  }

  onRunReport() {
    if (this.reportForm.valid) {
      let startDte = this.pipe.transform(this.startDteCtrl.value, 'yyyy-MM-dd') + ' 00:00:00';
      let endDte = this.pipe.transform(this.endDteCtrl.value, 'yyyy-MM-dd') + ' 23:59:59';
      let payload = {
        fromDte: startDte,
        toDte: endDte
      }
      this.transactions = [];
      this.loadedTransactions = [];
      this.dataSource.data = this.transactions;
      this.transactionService.getContactCount(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          data => {
            this.transactions = data;
            if (this.transactions.length === 0) {
              this.openSnackBar('No data found for the date selection', 'OK');
            } else {
              for (let index = 0; index < this.transactions.length; index++) {
                const element = this.transactions[index];
                this.loadedTransactions[index] = element;
              }
              this.transactions = this.loadedTransactions;
              this.loadedTransactions = [];
              this.dataSource.data = this.transactions;
            }
          });
    } else {
      this.openSnackBar('Please select a valid start and end date', 'OK');
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

}

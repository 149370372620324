import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatDialog } from '@angular/material';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { ServiceService } from '../../services/service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('snav', { static: false })
  sidenav!: MatSidenav;

  panelOpenState = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  token = localStorage.getItem('Authorization');

  private serviceDetails: any;

  private serviceName = '';
  private serviceCredits: number = 0;
  private showContactReport: boolean = false;

  constructor(
    // private observer: BreakpointObserver,
    // private router: Router
    private serviceService: ServiceService
  ) { }

  ngOnInit() {
    this.serviceDetails = '';
    this.serviceCredits = 0;
    if (this.token !== null) {
      this.loadServiceData();
    }
  }

  loadServiceData() {
    this.serviceService.getServiceDetails()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.serviceDetails = data;
        this.serviceName = this.serviceDetails.Service_No;
        let bucketLimit: number = this.serviceDetails.Service_Bucket.Value_Limit;
        let bucketCount: number = this.serviceDetails.Service_Bucket.Value_Count;
        this.serviceCredits = bucketLimit - bucketCount;
        if (this.serviceName === 'ws_cloudsell') {
          this.showContactReport = true;
        }
      });
  }

  onSidebarToggle() {
    this.sidenav.toggle()
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onLogOut() {
    localStorage.removeItem('Authorization');
    this.token = null;

  }


}

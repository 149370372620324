import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  token = localStorage.getItem('Authorization');

  private ngUnsubscribe: Subject<any> = new Subject();

  public loading: boolean;
  private totalQueued: any;
  private totalOutboundToday: any;
  private totalOutboundMonth: any;
  private totalInboundMonth: any;

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    // this.transactions = [];
    // this.loadedTransactions = [];
    this.loading = false;
    this.totalQueued = 0;
    this.totalOutboundToday = 0;
    this.totalOutboundMonth = 0;
    this.totalInboundMonth = 0;
    let count;

    this.dashboardService.getQueueCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          count = data;
          this.totalQueued = count.count;
        });

    this.dashboardService.getOutboundTodayCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          count = data;
          this.totalOutboundToday = count.count;
        });

    this.dashboardService.getOutboundMonthCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          count = data;
          this.totalOutboundMonth = count.count;
        });

    this.dashboardService.getInboundMonthCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          count = data;
          this.totalInboundMonth = count.count;
        });

  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
  }

  logout() {
    window.location.replace('/login');
    localStorage.removeItem('Authorization');
    this.token = null;
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   baseUrl: 'http://localhost:3000',
//   serviceUrl: 'http://localhost:3000/v1/service',
//   transactionUrl: 'http://localhost:3000/v1/sms',
//   dashboardUrl: 'http://localhost:3000/v1/dashboard',
// };

export const environment = {
  production: true,
  baseUrl: 'https://webapi2.stouf.com',
  serviceUrl: 'https://webapi2.stouf.com/v1/service',
  transactionUrl: 'https://webapi2.stouf.com/v1/sms',
  dashboardUrl: 'https://webapi2.stouf.com/v1/dashboard',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

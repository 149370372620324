import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatFormFieldModule, MatInputModule } from '@angular/material';

import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.css']
})
export class TransactionDetailsComponent {

  private ngUnsubscribe: Subject<any> = new Subject();

  transactionForm: FormGroup;
  transactionID = new FormControl('');
  transactionCellNo = new FormControl('');
  transactionContact = new FormControl('');
  transactionMessage = new FormControl('');
  transactionCreateDte = new FormControl('');
  transactionSMSCDte = new FormControl('');
  transactionDeliverDte = new FormControl('');
  transactionConcatNo = new FormControl('');
  transactionConcatMax = new FormControl('');
  transactionConcatRef = new FormControl('');
  transactionAppName = new FormControl('');
  transactionStatusDesc = new FormControl('');
  transactionError = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<TransactionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
  ) {
    if (data.element) {
      this.transactionID = data.element.Seq_No;
      this.transactionCellNo = data.element.Cell_No;
      this.transactionContact = data.element.Contact;
      this.transactionMessage = data.element.UD;
      this.transactionCreateDte = data.element.Create_Stamp;
      this.transactionSMSCDte = data.element.SMSC_Stamp;
      this.transactionDeliverDte = data.element.Deliver_Stamp;
      this.transactionConcatNo = (data.element.Concat_No === 0) ? 1 : data.element.Concat_No;
      this.transactionConcatMax = (data.element.Concat_Max === 0) ? 1 : data.element.Concat_Max;
      this.transactionConcatRef = data.element.Concat_Ref;
      this.transactionAppName = data.element.AppName;
      this.transactionStatusDesc = data.element.Status_Desc.Description;
      this.transactionError = data.element.Error;
    }
    this.transactionForm = this.formBuilder.group({
      'transactionID': [{ value: this.transactionID, disabled: true }],
      'transactionCellNo': [{ value: this.transactionCellNo, disabled: true }],
      'transactionContact': [{ value: this.transactionContact, disabled: true }],
      'transactionMessage': [{ value: this.transactionMessage, disabled: true }],
      'transactionCreateDte': [{ value: this.transactionCreateDte, disabled: true }],
      'transactionSMSCDte': [{ value: this.transactionSMSCDte, disabled: true }],
      'transactionDeliverDte': [{ value: this.transactionDeliverDte, disabled: true }],
      'transactionConcatNo': [{ value: this.transactionConcatNo, disabled: true }],
      'transactionConcatMax': [{ value: this.transactionConcatMax, disabled: true }],
      'transactionConcatRef': [{ value: this.transactionConcatRef, disabled: true }],
      'transactionAppName': [{ value: this.transactionAppName, disabled: true }],
      'transactionStatusDesc': [{ value: this.transactionStatusDesc, disabled: true }],
      'transactionError': [{ value: this.transactionError, disabled: true }],
    });
    this.transactionForm.markAllAsTouched();
  }

  onClose() {
    this.dialogRef.close();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

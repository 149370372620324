import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../environments/environment";
import { Observable, of } from 'rxjs';

import { Service } from '../models/service-model';

const serviceUrl = environment.serviceUrl;

@Injectable({
    providedIn: 'root'
})
export class ServiceService {
    token = localStorage.getItem('Authorization');

    httpOptions = {
        headers: {}
    };

    constructor(private httpClient: HttpClient) { }

    getServiceDetails(): Observable<Service> {
        if (this.token !== null) {
            this.httpOptions = {
                headers: {
                    'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
                    'Content-Type': 'application/json'
                }
            };
            return this.httpClient.get<Service>(serviceUrl + '/getservicedetails', this.httpOptions);
        }
        return;
    }
}
